
// Utils
import { formatList, throttle } from '@@/common/assets/js/utils/common-utils';
import { convertToObject } from '@@/common/assets/js/utils/query-utils';
import { mapActions } from 'vuex';

import blockComponentDict from 'portal/assets/js/constants/blockComponentDict';

// Блоки яндекс рекламы
const YANDEX_ADS = [
    // {
    //     id: 'R-A-12512853-4',
    //     slug: 'yandexAd',
    //     placeIndex: 2,
    //     adsId: 'R-A-12512853-4',
    // },
    // {
    //     id: 'R-A-12512853-5',
    //     slug: 'yandexAd',
    //     placeIndex: 5,
    //     adsId: 'R-A-12512853-5',
    // },
];

export default {
    name: 'IndexPage',

    layout: 'main',

    async asyncData({ error, $api, $axios, $sentry }) {
        try {
            const [
                companyNewsRes,
                mainNewsRes,
                latestMediaRes,
                mainData,
                blocksData,
                youtubeVideosRes,
                ratingCompaniesRes,
                vacanciesRes,
                vacanciesSpecs,
            ] = await Promise.all([
                $axios.$get($api.media.company),
                $axios.$get($api.media.list, {
                    params: {
                        is_main: true,
                        is_hide: false,
                        limit: 5,
                    },
                }),
                $axios.$get($api.media.latest),
                $axios.$get($api.main.data),
                $axios.$get($api.main.blocks),
                $axios.$get($api.media.youtube),
                $axios.$get($api.companies.rating, {
                    params: {
                        limit: 10,
                        offset: 0,
                    },
                }),
                $axios.$get($api.job.vacancy.list, { params: { is_top: true, limit: 4 } }),
                $axios.$get($api.job.vacancy.specs),
            ]);


            if ($sentry) {
                $sentry.captureException(new Error('test error in main page server side'));
            }

            const latestListPagination = {
                page: 1,
                limit: 12,
                hasNext: Boolean(latestMediaRes?.next),
                isLoad: false,
            };

            let vacancies = vacanciesRes?.results || [];

            if (vacancies.length < 4) {
                const extraVacanciesRes = await $axios.$get($api.job.vacancy.list, {
                    params: {
                        is_top: false,
                        limit: 4 - vacancies.length,
                    },
                });
                const extraVacancies = extraVacanciesRes?.results || [];
                vacancies = [...vacancies, ...extraVacancies];
            }

            const specs = convertToObject(vacanciesSpecs);
            vacancies = formatList(vacancies, specs, ['experience', 'category']);

            // Добавляем блоки Яндекс рекламы
            YANDEX_ADS.forEach(item => {
                if (blocksData?.blocks && blocksData.blocks.length >= item.placeIndex) {
                    blocksData.blocks.splice(item.placeIndex, 0, item);
                }
            });

            return {
                mainNewsList: mainNewsRes?.results || [],
                companyNewsList: companyNewsRes.results,
                youtubeVideos: youtubeVideosRes?.results || [],
                // companies,
                latestMediaList: latestMediaRes?.results || [],
                latestListPagination,
                // rating: ratingRes,
                mainData,
                blocksData,
                ratingCompanies: ratingCompaniesRes?.results || [],
                vacancies,
            };
        } catch (e) {
            console.warn('[MainPage/asyncData] request failed: ', e);
            return error({ statusCode: 404 });
        }
    },

    data() {
        return {
            mainData: {},
            blocksData: {},
            mainNewsList: [],
            companyNewsList: [],
            latestMediaList: [],
            latestListPagination: {
                page: 1,
                limit: 12,
                hasNext: false,
                isLoad: false,
            },

            rating: [],
            youtubeVideos: [],
            ratingCompanies: [],
            vacancies: [],
            timeoutId: null,

            showFooter: false,
            throttleScroll: throttle(this.onScroll, 100),
        };
    },

    head() {
        return {
            title: this.pageTitle,

            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.metaDescription,
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.pageTitle,
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: this.metaDescription,
                },
                {
                    hid: 'twitter:title',
                    name: 'twitter:title',
                    content: this.pageTitle,
                },
                {
                    hid: 'twitter:description',
                    name: 'twitter:description',
                    content: this.metaDescription,
                },
                {
                    hid: 'twitter:card',
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
            ],

            script: [
                { type: 'application/ld+json', json: this.jsonLd },
            ],
        };
    },

    computed: {

        mediaBlocks() {
            const mediaBlocks = this.blocksData.blocks.map((block, index) => {
                const slug = block.slug;
                const blockComponent = blockComponentDict[slug];

                let additionalFields = {};

                if (slug === 'vacancy') {
                    additionalFields = {
                        vacancies: this.vacancies,
                        vacanciesNumber: this.mainData.vacancies,
                        resumesNumber: this.mainData.resumes,
                        companiesNumber: this.mainData.companies_with_vacancies,
                    };
                }

                if (slug === 'partners') {
                    additionalFields = {
                        partners: this.mainData?.partners,
                    };
                }

                if (slug === 'rating') {
                    additionalFields = {
                        companies: this.ratingCompanies,
                        news: block.small_medias,
                    };
                }

                if (slug === 'events') {
                    additionalFields = {
                        events: this.mainData?.events,
                    };
                }

                return {
                    ...block,
                    big_media: block.big_media ?? {},
                    component: blockComponent,
                    firstScreenBlock: index === 0 || index === 1,
                    ...additionalFields,
                };
            });

            return mediaBlocks;
        },

        pageTitle() {
            return 'Движение.ру | Медиа о рынке недвижимости';
        },

        isEventsVisible() {
            return this.mainData?.events?.length > 0;
        },

        metaDescription() {
            return 'Главная страница информационного агентства «Движение.ру»: новости и статьи из мира недвижимости, интервью с участниками рынка, эксклюзивные аналитические исследования.';
        },

        jsonLd() {
            return [
                // WebSite
                {
                    '@context': 'https://schema.org',
                    '@graph': [
                        {
                            '@type': 'WebSite',
                            '@id': 'https://dvizhenie.ru/#website',
                            url: 'https://dvizhenie.ru/',
                            name: 'Движение.ру',
                            description: 'Сайт информационного агентства «Движение.ру»: ключевые новости рынка недвижимости',
                            publisher: {
                                '@type': 'Organization',
                                '@id': 'https://dvizhenie.ru/#organization',
                                url: 'https://dvizhenie.ru/',
                                email: 'info@dvizhenie.ru',
                                name: 'ООО Движение.ру',
                            },

                            copyrightHolder: {
                                '@id': 'https://dvizhenie.ru/#organization',
                            },

                            inLanguage: 'ru-RU',
                        },
                    ],
                },
            ];
        },
    },

    created() {
        this.changeVisibility(true);
    },

    mounted() {
        const { scrollId } = this.$route.query;

        if (scrollId) {
            this.timeoutId = setTimeout(() => {
                const target = document.getElementById(scrollId);

                target.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 300);
        }

        this.onScroll();
        window.addEventListener('scroll', this.throttleScroll);
    },

    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        window.removeEventListener('scroll', this.throttleScroll);
        this.throttleScroll = null;
    },

    methods: {
        ...mapActions('header', [
            'changeVisibility',
        ]),

        onScroll() {
            this.showFooter = document.documentElement.scrollTop >= 10;
        },

        openVideos() {
            this.$modal.open(() => import('portal/components/modals/InsightModal'), {
                items: this.youtubeVideos,
            });
        },

        async getMoreNews(update = false) {
            if (!this.latestListPagination.hasNext) {
                return;
            }
            this.latestListPagination.isLoad = true;
            this.latestListPagination.page += 1;

            const { data } = await this.fetchNews();

            this.latestListPagination.hasNext = Boolean(data.next);

            this.latestMediaList = [...this.latestMediaList, ...data?.results || []];

            this.latestListPagination.isLoad = false;

            if (update) {
                this.updateModal();
            }
        },

        async fetchNews() {
            try {
                return await this.$axios(this.$api.media.latest, {
                    params: {
                        limit: this.latestListPagination.limit,
                        offset: (this.latestListPagination.page - 1) * this.latestListPagination.limit,
                    },
                });
            } catch (e) {
                console.warn('[LatestNews/fetch] failed with ', e);
            }
        },

        openNewsModal() {
            this.$modal.open(() => import('portal/components/modals/NewsModal'), {
                news: this.latestMediaList,
                pagination: this.latestListPagination,
            }, () => {
                this.$modal.event.$off('get-more');
            });

            this.$modal.event.$on('get-more', () => this.getMoreNews(true));
        },

        updateModal() {
            this.$modal.update({
                news: this.latestMediaList,
                pagination: this.latestListPagination,
            });
        },
    },
};
